import React from 'react'
import {Review} from "./Review";

import GermanReviews from '../../lang/de/reviews.json';
import EnglishReviews from '../../lang/en/reviews.json';
import FrenchReviews from '../../lang/fr/reviews.json';
import DutchReviews from '../../lang/nl/reviews.json';
import NorwegianReviews from '../../lang/no/reviews.json';
import ItalianReviews from '../../lang/it/reviews.json';
import DanishReviews from '../../lang/dk/reviews.json';
import PolishReviews from '../../lang/pl/reviews.json';
import FinishReviews from '../../lang/fi/reviews.json';
import SpanishReviews from '../../lang/es/reviews.json';
import SwissReviews from '../../lang/ch/reviews.json';
import {Box, Text} from "@chakra-ui/react";
import {FormattedMessage} from "react-intl";

export const Reviews = (props) => {
    let allReviews = {
        de: GermanReviews,
        at: GermanReviews,
        fr: FrenchReviews,
        nl: DutchReviews,
        en: EnglishReviews,
        no: NorwegianReviews,
        it: ItalianReviews,
        dk: DanishReviews,
        pl: PolishReviews,
        fi: FinishReviews,
        es: SpanishReviews,
        ch: SwissReviews
    };

    const reviews = allReviews[props.locale];

    const reviewsContent = reviews.map((review, index) => {
        return (
            <Review key={"review-" + index}
                    author={review.author}
                    date={review.date}
                    score={review.score}
                    review={review.review}/>
        )
    });

    const totalScore = reviews => {
        let totalScore = 0;

        reviews.forEach(review => {
            totalScore += review.score;
        });

        let avgRating = Math.round((totalScore / reviews.length) * 100) / 100;

        return avgRating.toFixed(1);
    }

    return (
        <>
            <Box align="center">
                <Text fontSize="3xl"><FormattedMessage id="complete.product.review.headline"/></Text>
                <Text fontSize="6xl">{totalScore(reviews)}</Text>
            </Box>

            {reviewsContent}
        </>
    )
}
