import {Box, Button} from "@chakra-ui/react";
import Step from "../../components/step/Step";
import {useNavigate} from "react-router-dom";
import BodySelector from "../../components/body-selector/BodySelector";
import useStore from "../../storage/DataStore";
import {FormattedMessage} from "react-intl";

function Step1() {
    let navigate = useNavigate();
    const state = useStore.getState();

    return (
        <Step heading={'step1.heading'}
              description={'step1.description'}
              currentStep={1}
              backLink={'/'}>
            <Box p={5} pb={0}>
                <BodySelector gender={state.gender}/>
            </Box>
            <Button bg="brand"
                    onClick={() => navigate('../step2', {replace: true})}
                    color={'white'}
                    size="md"
                    mt={5}
                    fontWeight={700}
                    w={'100%'}>
                <FormattedMessage id="button.next" />
            </Button>
        </Step>
    );
}

export default Step1;
