import "./Gummies.scss";

function GummiesTemplate(props) {
    return (
        <div className={"page gummies"}>
            {props.children}
        </div>
    );
}

export default GummiesTemplate;
