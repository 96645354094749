import Step from "../../components/step/Step";
import {Form, Field, Formik} from 'formik';
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel, Grid, Input,
    InputGroup,
    NumberInput,
    InputRightAddon, Select, NumberInputField, NumberInputStepper, NumberIncrementStepper
} from "@chakra-ui/react";
import * as Yup from 'yup';
import {useNavigate} from "react-router-dom";
import useStore from "../../storage/DataStore";
import {FormattedMessage, useIntl} from "react-intl";

function createAgeRange(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

function Step2(props) {
    const intl = useIntl()
    const navigate = useNavigate();

    const UserDataSchema = Yup.object().shape({
        weight: Yup.number()
            .max('300', intl.formatMessage({id: 'form.weight.required'}))
            .required(intl.formatMessage({id: 'form.weight.required'}))
            .typeError(intl.formatMessage({id: 'form.message.isNumber'})),
        targetWeight: Yup.number()
            .max('300', intl.formatMessage({id: 'form.targetWeight.required'}))
            .required(intl.formatMessage({id: 'form.targetWeight.required'}))
            .typeError(intl.formatMessage({id: 'form.message.isNumber'})),
        bodyHeight: Yup.number()
            .required(intl.formatMessage({id: 'form.bodyHeight.required'}))
            .typeError(intl.formatMessage({id: 'form.message.isNumber'})),
        age: Yup.number()
            .required(intl.formatMessage({id: 'form.age.chooseAge'}))
    });

    const ageRange = createAgeRange(18, 99);

    let unit = 'kg';

    if (props.config.locale === 'en') {
        unit = 'st';
    }

    return (
        <Step heading={'step2.heading'}
              description={'step2.description'}
              currentStep={2}
              backLink={'/step1'}>
            <Formik
                initialValues={{
                    weight: useStore.getState().weight,
                    targetWeight: useStore.getState().targetWeight,
                    bodyHeight: useStore.getState().bodyHeight,
                    age: useStore.getState().age
                }}
                validationSchema={UserDataSchema}
                onSubmit={(values, actions) => {
                    useStore.setState({
                        weight: values.weight,
                        targetWeight: values.targetWeight,
                        bodyHeight: values.bodyHeight,
                        age: values.age
                    });
                    navigate('../loader', {replace: true});
                }}
            >
                {(props) => (
                    <Form>
                        <Grid
                            mt={5}
                            templateColumns="repeat(2, 1fr)"
                            gap={10}
                            mb={5}
                        >
                            <Box>
                                <Field name={'weight'}>
                                    {({field, form}) => (
                                        <FormControl isInvalid={form.errors.weight && form.touched.weight}>
                                            <FormLabel fontWeight={700} htmlFor="weight"><FormattedMessage
                                                id={'form.weight.label'}/></FormLabel>

                                            {unit === 'st' &&
                                                <NumberInput precision={1} step={0.1}>
                                                    <NumberInputField {...field} id="weight"/>
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper/>
                                                        <NumberIncrementStepper/>
                                                    </NumberInputStepper>
                                                </NumberInput>
                                            }
                                            {unit === 'kg' &&
                                                <InputGroup>
                                                    <Input pattern={'[0-9]*'} inputMode={'number'} {...field}
                                                           id="weight"/>

                                                    <InputRightAddon background={'#e5e4e0'} pointerEvents="none">
                                                        {unit}
                                                    </InputRightAddon>
                                                </InputGroup>
                                            }

                                            <FormErrorMessage>{form.errors.weight}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                            </Box>

                            <Box>
                                <Field name={'targetWeight'}>
                                    {({field, form}) => (
                                        <FormControl isInvalid={form.errors.targetWeight && form.touched.targetWeight}>
                                            <FormLabel fontWeight={700} htmlFor="targetWeight"><FormattedMessage
                                                id={'form.targetWeight.label'}/></FormLabel>

                                            {unit === 'st' &&
                                                <NumberInput precision={1} step={0.1}>
                                                    <NumberInputField {...field} id="targetWeight"/>
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper/>
                                                        <NumberIncrementStepper/>
                                                    </NumberInputStepper>
                                                </NumberInput>
                                            }
                                            {unit === 'kg' &&
                                                <InputGroup>
                                                    <Input pattern={'[0-9]*'} inputMode={'number'} {...field}
                                                           id="targetWeight"/>

                                                    <InputRightAddon background={'#e5e4e0'} pointerEvents="none">
                                                        {unit}
                                                    </InputRightAddon>
                                                </InputGroup>
                                            }

                                            <FormErrorMessage>{form.errors.targetWeight}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                            </Box>
                        </Grid>

                        <Box mb={5}>
                            <Field name={'bodyHeight'}>
                                {({field, form}) => (
                                    <FormControl isInvalid={form.errors.bodyHeight && form.touched.bodyHeight}>
                                        <FormLabel fontWeight={700} htmlFor="bodyHeight"><FormattedMessage
                                            id={'form.bodyHeight.label'}/></FormLabel>

                                        {unit === 'st' &&
                                            <NumberInput precision={1} step={0.1}>
                                                <NumberInputField {...field} id="bodyHeight"/>
                                                <NumberInputStepper>
                                                    <NumberIncrementStepper/>
                                                    <NumberIncrementStepper/>
                                                </NumberInputStepper>
                                            </NumberInput>
                                        }
                                        {unit === 'kg' &&
                                            <InputGroup>
                                                <Input pattern={'[0-9]*'} inputMode={'number'} {...field}
                                                       id="bodyHeight"/>

                                                <InputRightAddon background={'#e5e4e0'} pointerEvents="none">
                                                    <FormattedMessage id="form.bodyHeight.addon"/>
                                                </InputRightAddon>
                                            </InputGroup>
                                        }

                                        <FormErrorMessage>{form.errors.bodyHeight}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Box>

                        <Box>
                            <Field name={'age'}>
                                {({field, form}) => (
                                    <FormControl isInvalid={form.errors.age && form.touched.age}>
                                        <FormLabel fontWeight={700} htmlFor="age"><FormattedMessage
                                            id={'form.age.label'}/></FormLabel>

                                        <InputGroup>
                                            <Select {...field} id="age">
                                                <option
                                                    value={''}>{intl.formatMessage({id: 'form.age.chooseAge'})}</option>
                                                <option
                                                    value={17}>{intl.formatMessage({id: 'form.age.under18'})}</option>
                                                {ageRange.map(index => {
                                                    return (
                                                        <option key={index}
                                                                value={index}>{index} {intl.formatMessage({id: 'form.age.option'})}</option>
                                                    )
                                                })}
                                            </Select>
                                        </InputGroup>

                                        <FormErrorMessage>{form.errors.age}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                        </Box>

                        <Button bg="brand"
                                color={'white'}
                                size="md"
                                mt={5} w={'100%'}
                                fontWeight={700}
                                type="submit">
                            <FormattedMessage id="button.next"/>
                        </Button>
                    </Form>
                )}
            </Formik>
        </Step>
    );
}

export default Step2;
