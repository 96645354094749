import Male01 from "../../assets/body-shapes/male_01.png";
import Male02 from "../../assets/body-shapes/male_02.png";
import Male03 from "../../assets/body-shapes/male_03.png";
import Male04 from "../../assets/body-shapes/male_04.png";
import Male05 from "../../assets/body-shapes/male_05.png";
import Female01 from "../../assets/body-shapes/female_01.png";
import Female02 from "../../assets/body-shapes/female_02.png";
import Female03 from "../../assets/body-shapes/female_03.png";
import Female04 from "../../assets/body-shapes/female_04.png";
import Female05 from "../../assets/body-shapes/female_05.png";

function BodyImage(props) {
    let preloadSrcList = [];

    if (props.gender === 'male') {
        preloadSrcList = [
            Male01,
            Male02,
            Male03,
            Male04,
            Male05
        ]
    } else {
        preloadSrcList = [
            Female01,
            Female02,
            Female03,
            Female04,
            Female05
        ]
    }

    for (const i of preloadSrcList) {
        const img = new Image();
        img.src = i;
    }

    return (
        <>
            {props.gender === 'male' &&
                <>
                    <img style={{ display: props.bodyType === 1 ? 'block' : 'none' }} src={Male01} alt="" />
                    <img style={{ display: props.bodyType === 2 ? 'block' : 'none' }} src={Male02} alt="" />
                    <img style={{ display: props.bodyType === 3 ? 'block' : 'none' }} src={Male03} alt="" />
                    <img style={{ display: props.bodyType === 4 ? 'block' : 'none' }} src={Male04} alt="" />
                    <img style={{ display: props.bodyType === 5 ? 'block' : 'none' }} src={Male05} alt="" />
                </>
            }
            {props.gender === 'female' &&
                <>
                    <img style={{ display: props.bodyType === 1 ? 'block' : 'none' }} src={Female01} alt="" />
                    <img style={{ display: props.bodyType === 2 ? 'block' : 'none' }} src={Female02} alt="" />
                    <img style={{ display: props.bodyType === 3 ? 'block' : 'none' }} src={Female03} alt="" />
                    <img style={{ display: props.bodyType === 4 ? 'block' : 'none' }} src={Female04} alt="" />
                    <img style={{ display: props.bodyType === 5 ? 'block' : 'none' }} src={Female05} alt="" />
                </>
            }
        </>
    )
}

export default BodyImage;
