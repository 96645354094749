import * as React from "react";
import {ChakraProvider} from "@chakra-ui/react"
import {
    MemoryRouter,
    Route,
    Routes
} from "react-router-dom";
import {IntlProvider} from "react-intl";
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import German from './lang/de.json';
import English from './lang/en.json';
import French from './lang/fr.json';
import Dutch from './lang/nl.json';

import GummiesTemplate from "./templates/GummiesTemplate";
import ChakraTheme from "./templates/Chakra";
import Home from "./pages/home/Home";
import Step2 from "./pages/step2/Step2";
import Step1 from "./pages/step1/Step1";
import Loader from "./pages/loader/Loader";
import Complete from "./pages/complete/Complete";

function App() {
    const currentUrl = window.location.toString();

    let messages = German;
    let locale = 'de';

    switch (true) {
        case (currentUrl.search('/at') !== -1):
            locale = 'at';
            messages.outgoingUrl = 'https://n-drops-shop.com/at/jetzt-kaufen.html';
            break;

        case (currentUrl.search('/ch') !== -1):
            locale = 'ch';
            messages.outgoingUrl = 'https://ndrops-shop.com/ch/jetzt-kaufen.html';
            break;

        case (currentUrl.search('/uk') !== -1):
            messages = English;
            locale = 'en';
            break;

        case (currentUrl.search('/ie') !== -1):
            messages = English;
            locale = 'en';
            messages.outgoingUrl = 'https://n-drops-shop.com/ie/buy-now.html';
            break;

        case (currentUrl.search('/fr') !== -1):
            messages = French;
            locale = 'fr';
            break;

        case (currentUrl.search('/nl') !== -1):
            messages = Dutch;
            locale = 'nl';
            break;

        case (currentUrl.search('/befr') !== -1):
            messages = French;
            messages.outgoingUrl = 'https://n-drops-shop.com/befr/acheter-maintenant.html';
            locale = 'fr';
            moment.locale('fr');
            break;

        case (currentUrl.search('/benl') !== -1):
            messages = Dutch;
            messages.outgoingUrl = 'https://n-drops-shop.com/benl/nu-kopen.html';
            locale = 'nl';
            moment.locale('nl');
            break;

        case (currentUrl.search('/lude') !== -1):
            messages.outgoingUrl = 'https://n-drops-shop.com/lude/jetzt-kaufen.html';
            break;

        case (currentUrl.search('/lufr') !== -1):
            messages = French;
            messages.outgoingUrl = 'https://n-drops-shop.com/lufr/acheter-maintenant.html';
            locale = 'fr';
            moment.locale('fr');
            break;

        default:
    }

    document.title = messages.title;

    moment.locale(locale);

    const config = {
        locale: locale
    }

    return (
        <MemoryRouter>
            <ChakraProvider theme={ChakraTheme}>
                <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                    <GummiesTemplate>
                        <Routes>
                            <Route path='/' element={
                                <Home/>
                            }/>
                            <Route path='/step1' element={
                                <Step1/>
                            }/>
                            <Route path='/step2' element={
                                <Step2 config={config}/>
                            }/>
                            <Route path='/loader' element={
                                <Loader/>
                            }/>
                            <Route path='/complete' element={
                                <Complete config={config}/>
                            }/>
                        </Routes>
                    </GummiesTemplate>
                </IntlProvider>
            </ChakraProvider>
        </MemoryRouter>
    );
}

export default App;
