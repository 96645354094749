import { Text, Box, Container, Heading } from "@chakra-ui/react";
import { Steps } from "../steps/Steps";
import {FormattedMessage} from "react-intl";
function Step(props) {
  return (
    <Container p={[1,5]}>
      <Box
        background={"white"}
        borderWidth="1px"
        borderRadius="lg"
        p={[3,5]}
        textAlign={"center"}
      >
        <div className="steps-counter-container">
          <Steps currentStep={props.currentStep} maxSteps="2" backLink={props.backLink} />
        </div>

        <Heading as={"h2"} size={"lg"} mb={5}>
          <FormattedMessage id={props.heading} />
        </Heading>

        {props.description && <Text pl={3} pr={3}><FormattedMessage id={props.description} /></Text>}

        {props.children}
      </Box>
    </Container>
  );
}

export default Step;
