import create from 'zustand'

const useStore = create(set => ({
    gender: '',
    bodyType: 3,
    weight: '',
    targetWeight: '',
    bodyHeight: '',
    age: ''
}));

export default useStore;
