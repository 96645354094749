import React from 'react'
import {Box, Heading, Badge, Spacer, Text} from "@chakra-ui/react"
import {StarIcon} from "@chakra-ui/icons";
import {FormattedMessage} from "react-intl";

export const Review = (props) => {
    return (
        <Box mt={5} overflow="hidden">
            <Box>
                <Box d="flex">
                    <Box>
                        <Heading as="h3" size="sm">
                            {props.author}
                        </Heading>

                        {Array(5)
                            .fill("")
                            .map((_, i) => (
                                <StarIcon
                                    key={i}
                                    w={4}
                                    height={4}
                                    color={i < props.score ? "yellow.500" : "gray.300"}
                                />
                            ))}
                    </Box>
                    <Spacer/>
                    <Box>
                        <Box>
                            <Text fontSize={'sm'}><FormattedMessage id="review.date" values={{"date": props.date}}/></Text>

                            <Badge colorScheme="green">
                                <FormattedMessage id="review.verifiedPurchase"/>
                            </Badge>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box mt={2} pt={2} mb={4} borderTopWidth="1px">{props.review}</Box>
        </Box>
    )
}
